<template>
  <div>
    <div class="form-group">
      <b>Denumire necesitate</b>
      <input type="text" v-model="productName" class="vInput" />
    </div>

    <div class="form-group">
      <b>Tip poziție catalog</b>
      <v-select
        :options="productTypes"
        label="name"
        v-model="selectedProductType"
        :searchable="false"
      />
    </div>
    <div class="form-group">
      <b>Unitatea de măsură</b>
      <v-select
        v-model="selectedMeasureUnits"
        label="name"
        :filter="(a, b) => fuseFilter(a, b, 'name')"
        :options="measureUnits"
      />
    </div>

    <template v-if="apiLoading">
      <i class="fas fa-sync fa-spin fa-2x"></i>
    </template>
    <template v-else>
      <div class="form-group">
        <b>Cod CPV</b>
        <v-select-infinite
          v-model="selected"
          label="cpvCode,name"
          :startup="!readonly"
          :apiModule="apiModule"
          :apiOptions="{ prepareSearch }"
          :totalOptions="totalOptions"
          :totalRecordsCount="totalRecordsCount"
          :no-drop="readonly"
          :clearable="!readonly"
          :overflowPrevent="true"
          :reloadCount="reloadCount"
        >
        </v-select-infinite>
      </div>
    </template>
    <div class="form-group colFlex noMB MTmobile1" style="flex-grow: 1">
      <b style="line-height: 1.2">Descriere poziție de catalog</b>
      <pre
        class="mobileClampFontSize"
        style="height: 100%; margin: 0; white-space: pre-wrap"
        v-html="itemDescription"
      ></pre>
    </div>
  </div>
</template>

<script>
import { MEASUREMENT_UNIT, CPV_CATEGORY_PAGINATED } from "@/api.js";

export default {
  name: "CodCpvSelect",
  props: {
    initial: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiModule: CPV_CATEGORY_PAGINATED,
      totalOptions: [],
      totalRecordsCount: 0,
      selected: this.initial.cpvCategory,
      productTypes: [
        { name: "Produse", id: 1 },
        { name: "Servicii", id: 2 },
        { name: "Lucrări", id: 3 },
      ],
      newData: this.initial,
      selectedProductType: {},
      measureUnits: [],
      selectedMeasureUnits: {},
      productName: this.initial.name,
      reloadCount: 0,
      getSelectSchema() {
        return {
          value: null,
          id: typeof this.makeid == "function" ? this.makeid(8) : Date.now(),
          excluded: false,
          canEdit: true,
        };
      },
      apiLoading: false,
    };
  },

  watch: {
    selected: {
      handler(val) {
        this.newData = { ...this.newData, cpvCategory: val };
        this.$emit("input", this.newData);
      },
    },
    selectedMeasureUnits: {
      handler(val) {
        this.newData = { ...this.newData, measurementUnit: val };
        this.$emit("input", this.newData);
      },
    },
    productName: {
      handler(val) {
        this.newData = { ...this.newData, name: val };
        this.$emit("input", this.newData);
      },
    },
    selectedProductType(val) {
      this.reloadCount++;
    },
    deep: true,
    immediate: true,
  },
  computed: {
    itemDescription() {
      return this.selected
        ? `<b>Denumire</b>: ${this.selected.cpvCode} - ${this.selected.name}\n<b>Diviziunea</b>: ${this.selected.division}\n<b>Grupul</b>: ${this.selected.group}\n<b>Clasa</b>: ${this.selected.class}\n<b>Categoria</b>: ${this.selected.category}`
        : "";
    },
  },

  methods: {
    async getMeasureUnits() {
      let x = await MEASUREMENT_UNIT();
      return x.data.result;
    },
    getBudgetLabel(x) {
      return x?.budgetClasification?.name || "";
    },
    addOption() {
      if (!this.canAdd) return;

      this.selected.push(this.getSelectSchema());
    },
    prepareSearch(x) {
      return [
        this.selectedProductType.id,
        ["columnsOr", `cpvCode,name,division,group,class,category||${x}`],
      ];
    },
    async convertedMuData() {
      this.selectedMeasureUnits = {
        id: this.initial.measurementUnit.id,
        name: `${this.initial.measurementUnit.shortName} — „${this.initial.measurementUnit.description}”`,
      };

      const units = await this.getMeasureUnits();

      this.measureUnits = units.map((e) => ({
        id: e.id,
        name: `${e.shortName} — „${e.description}”`,
      }));
    },
  },

  created() {
    this.convertedMuData();
    this.selectedProductType = this.productTypes.find(
      (el) => el.id == this.initial.cpvCategory.cpvType
    );

    this.$emit("input", this.newData);
  },
};
</script>

<style></style>
